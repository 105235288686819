/**
 * Function that returns full name for a user/member/mentor
 * @function getFullName
 * @param {User|Member|Mentor} obj - User, Member or Mentor instance
 * @returns {string} - full name
 */
const getFullName = (obj, format, t) =>
  format(t('%(firstName)s %(lastName)s'), {
    firstName: obj && obj.first_name,
    lastName: obj && obj.last_name,
  });

export default getFullName;
