import { formatAsQueryParams } from 'utils/queryString';
import api from './api';

const fetchApplication = ({ id }) => api.get(`/api/member_application/${id}/`);
const fetchManagementPanelMemberApplication = ({ id, programPhase, program }) => {
  const actualStage = ['application', 'selection', 'approved'].includes(programPhase)
    ? 'application'
    : programPhase;
  return api.get(`/api/management-panel/members/${actualStage}/${id}/`, {
    params: {
      program,
      application_stage:
        programPhase === 'dropped' || programPhase === 'rejected' ? null : programPhase,
    },
  });
};
const createApplication = ({ program, ...application }) =>
  api.post('/api/member_application/', { program, ...application });
const listApplications = () => api.get('/api/member_application/');

const updateApplication = application =>
  api.patch(`/api/member_application/${application.id}/`, { ...application });

const updateApplicationBulk = (payload = {}, programPhase = undefined) => {
  const actualStage = ['application', 'selection', 'approved'].includes(programPhase)
    ? 'application'
    : programPhase;
  const payloadClone = { ...payload, query: payload.query ?? {} };
  const { query, ...rest } = payloadClone;

  const queryString = formatAsQueryParams(query, true);

  return api.patch(`/api/management-panel/members/${actualStage}/bulk/?${queryString}`, {
    params: query,
    ...rest,
  });
};

const moreActionsApplicationManagementPanel = data =>
  api.post(`/api/entrepreneur-applications/${data.id}/status`, { ...data });

const validateApplicationStatus = ({ projectId, programId }) => {
  api.get(`/api/member_application_status/?project=${projectId}&program=${programId}`);
};

const listMemberApplication = (query = {}, programPhase) => {
  const actualStage = ['application', 'selection', 'approved'].includes(programPhase)
    ? 'application'
    : programPhase;
  return api.get(`/api/management-panel/members/${actualStage}`, {
    params: query,
  });
};

export default {
  createApplication,
  fetchApplication,
  listApplications,
  updateApplication,
  updateApplicationBulk,
  moreActionsApplicationManagementPanel,
  validateApplicationStatus,
  listMemberApplication,
  fetchManagementPanelMemberApplication,
};
