// Libraries
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

// Components
import { ExclamationOutlined } from '@ant-design/icons';
import { H2, P } from 'components/fontSystem';
import Panel from '.';

const InfoPanel = ({
  children = null,
  message = '',
  noIcon = false,
  title = null,
  icon = null,
  type = 'yellow',
  style = {},
  ...rest
}) => {
  const renderedIcon = icon || <ExclamationOutlined />;

  return (
    <StyledPanel type={type} style={style} className="info-panel" {...rest}>
      {/* Icon column */}
      {!noIcon && <div>{renderedIcon}</div>}
      {/* Content column */}
      <div>
        {/* Title */}
        {title && (
          <H2 $semibold className="title">
            {title}
          </H2>
        )}
        {/* Text or other content */}
        {children || <P $italic $mb0 dangerouslySetInnerHTML={{ __html: message }} />}
      </div>
    </StyledPanel>
  );
};

const StyledPanel = styled(Panel)`
  &&& {
    padding: ${props => (props.padding ? `${props.padding}px` : '15px')};
    margin: ${({ mt, mb, ml, mr }) => !mt && !mb && !ml && !mr && 0};

    display: flex;
    gap: 10px;

    .anticon {
      color: white;
      border-radius: 100%;
      padding: 5px;
    }

    .title {
      font-size: 18px;
    }

    ul {
      padding-inline: 0;
    }

    ${props =>
      props.type === 'green' &&
      css`
        background-color: ${`${props.theme.memberGreen}1A`};

        .title {
          color: ${props.theme.memberGreen};
        }

        .anticon {
          background-color: ${props.theme.memberGreen};
        }
      `}

    ${props =>
      props.type === 'teal' &&
      css`
        background-color: ${`${props.theme.teal}1A`};

        .title {
          color: ${props.theme.teal};
        }

        .anticon {
          background-color: ${props.theme.teal};
        }
      `}

    ${props =>
      props.type === 'yellow' &&
      css`
        background-color: ${`${props.theme.warning}1A`};

        .title {
          color: ${props.theme.warning};
        }

        .anticon {
          background-color: ${props.theme.warning};
        }
      `}

    ${props =>
      props.type === 'pink' &&
      css`
        background-color: ${`${props.theme.parrotPink}1A`};

        .title {
          color: ${props.theme.parrotPink};
        }

        .anticon {
          background-color: ${props.theme.parrotPink};
        }
      `}
  }
`;

InfoPanel.propTypes = {
  children: PropTypes.node,
  message: PropTypes.string,
  padding: PropTypes.number,
  title: PropTypes.string,
  icon: PropTypes.node,
  noIcon: PropTypes.bool,
  type: PropTypes.string,
  style: PropTypes.instanceOf(Object),
};

export default InfoPanel;
