// Libraries
// eslint-disable-next-line import/no-absolute-path, import/no-unresolved
import '/node_modules/flag-icons/css/flag-icons.min.css';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import styled, { ThemeProvider } from 'styled-components';
import { ConfigProvider } from 'antd';
import * as Sentry from '@sentry/react';
import dayjs from 'dayjs';
import { BrowserRouter } from 'react-router-dom';

// Dependencies
import 'styles/index.css';
import 'styles/chatbox.css';
import { getAntTheme } from 'styles/antTheme';
import withErrorHandler from 'hocs/withErrorHandler';
import Routes from 'containers/Routes';
import { TranslationsProvider } from 'providers/translationsProvider';
import getTranslationHandlers, { languages } from 'utils/translationHandlers';
import queryString from 'utils/queryString';
import { customization as customizationKey, getLocalStorageItem } from 'utils/localStorage.js';
import { initBeforeUnload } from 'utils/beforeUnload';
import isoWeek from 'dayjs/plugin/isoWeek';
import 'dayjs/locale/es';
import 'dayjs/locale/fr';
import 'dayjs/locale/pt';
import 'dayjs/locale/hy-am';
import 'dayjs/locale/th';
import 'dayjs/locale/vi';
import 'dayjs/locale/en-gb';
import 'dayjs/locale/it';
import 'dayjs/locale/sk';
import 'dayjs/locale/lv';
import 'dayjs/locale/km';
import 'dayjs/locale/lo';

// Actions and selectors
import { actions as authActions, selectors as userSelectors } from 'store/modules/user';
import {
  actions as customizationActions,
  selectors as customizationSelectors,
} from 'store/modules/customization';
import { selectors as langSelectors } from 'store/modules/translations';

// TODO: This file should in in styles, not here.
import GlobalStyle from './globalStyle';

const WrapperComponent = styled.div`
  min-height: 100vh;
`;

const getPageTitle = () => {
  if (window.prod) {
    return 'Bridge for Billions';
  }

  if (window.staging) {
    return 'Bridge for Billions - Demo';
  }

  return 'Dev - Bridge for Billions';
};

dayjs.extend(isoWeek);

const setLanguage = lang => {
  switch (lang) {
    case 'es_es':
      dayjs.locale('es');
      break;
    case 'fr_fr':
      dayjs.locale('fr');
      break;
    case 'pt_br':
      dayjs.locale('pt-br');
      break;
    case 'pt_pt':
      dayjs.locale('pt');
      break;
    case 'hy_hy':
      dayjs.locale('hy-am');
      break;
    case 'th_th':
      dayjs.locale('th');
      break;
    case 'vi_vi':
      dayjs.locale('vi');
      break;
    case 'it_it':
      dayjs.locale('it');
      break;
    case 'sk_sk':
      dayjs.locale('sk');
      break;
    case 'lv_lv':
      dayjs.locale('lv');
      break;
    case 'km_km':
      dayjs.locale('km');
      break;
    case 'lo_lo':
      dayjs.locale('lo');
      break;
    default:
      dayjs.locale('en-gb');
  }
};

const App = () => {
  const dispatch = useDispatch();

  const { program } = queryString.parse(document.location.search);
  const localStorageCustomization = getLocalStorageItem(customizationKey);
  const localStorageProgram = Number(localStorageCustomization);

  const customization = useSelector(customizationSelectors.getCustomization);
  const isFetching = useSelector(customizationSelectors.isFetching);
  const selectedLanguage = useSelector(langSelectors.getSelectedLanguage);
  const userIsAccessible = useSelector(userSelectors.userIsAccessible);
  const user = useSelector(userSelectors.getUser);

  const configProviderTheme = getAntTheme(selectedLanguage);
  setLanguage(selectedLanguage);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const joinList = urlParams.get('join-list');
    if (joinList) {
      sessionStorage.setItem('joinList', joinList);
    }
  }, []);

  useEffect(() => {
    dispatch(authActions.userRestore());
    initBeforeUnload();
  }, []);

  useEffect(() => {
    dispatch(customizationActions.fetchCustomizationSucceeded({ customization }));
  }, [user.id]);

  if (userIsAccessible && !user.id && !customization.id) {
    if (program) {
      dispatch(customizationActions.fetchCustomization({ program }));
    } else if (localStorageCustomization) {
      dispatch(
        customizationActions.fetchCustomization({
          program: localStorageProgram,
        })
      );
    }
  }

  const translationHandlers = getTranslationHandlers(selectedLanguage);
  const theme = customization.mainTheme;

  const pageTitle = getPageTitle();

  if (isFetching) return null;

  return (
    <ThemeProvider theme={theme}>
      <ConfigProvider theme={configProviderTheme}>
        <TranslationsProvider value={translationHandlers}>
          <GlobalStyle />
          <WrapperComponent>
            <Helmet>
              <meta charSet="utf-8" />
              <title>{pageTitle}</title>

              {/* Google font Phetsarath for LAO language */}
              {selectedLanguage === languages.LO && (
                <link
                  href="https://fonts.googleapis.com/css2?family=Phetsarath:wght@400;700&display=swap"
                  rel="stylesheet"
                />
              )}
            </Helmet>
            {userIsAccessible && (
              <BrowserRouter>
                <Routes />
              </BrowserRouter>
            )}
          </WrapperComponent>
        </TranslationsProvider>
      </ConfigProvider>
    </ThemeProvider>
  );
};

const WrappedApp = withErrorHandler(Sentry.withProfiler(App));
export default WrappedApp;
