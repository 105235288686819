import styled, { css } from 'styled-components';
import { Button as AntButton } from 'antd';
import { getValueForSize } from 'styles/utils';
import { mainTheme, memberTheme, mentorTheme, parrotTheme } from 'styles/themes';
import { fontWeightStyle } from 'components/fontSystem';

const hPaddingLarge = '20px';
const hPaddingDefault = '15px';
const hPaddingSmall = '10px';

const getPadding = props =>
  getValueForSize(props, {
    large: `15px ${hPaddingLarge}`,
    default: `10px ${hPaddingDefault}`,
    small: `5px ${hPaddingSmall}`,
  });

const getMarginLeft = props =>
  getValueForSize(props, {
    large: `-${hPaddingLarge}`,
    default: `-${hPaddingDefault}`,
    small: `-${hPaddingSmall}`,
  });

const getTextTransform = props =>
  getValueForSize(props, {
    large: 'uppercase',
    default: 'uppercase',
    small: 'initial',
  });

const blinkKeyFrames = theme => css`
  @keyframes ${theme.type}-blink {
    0% {
      box-shadow: 0 0 1px 0px ${theme.primaryColorFade};
    }
    10% {
      box-shadow: 0 0 1px 0px ${theme.primaryColorFade};
    }
    50% {
      box-shadow: 0 0 1px 5px ${theme.primaryColorFade};
    }
    90% {
      box-shadow: 0 0 1px 0px ${theme.primaryColorFade};
    }
  }
  @keyframes white-blink {
    0% {
      box-shadow: 0 0 1px 0px rgba(255, 255, 255, 0.3);
    }
    10% {
      box-shadow: 0 0 1px 0px rgba(255, 255, 255, 0.3);
    }
    50% {
      box-shadow: 0 0 1px 5px rgba(255, 255, 255, 0.3);
    }
    90% {
      box-shadow: 0 0 1px 0px rgba(255, 255, 255, 0.3);
    }
  }
`;

const Button = styled(AntButton)`
  &&& {
    width: fit-content;

    ${fontWeightStyle}

    border: ${props => props.border || 'none'};
    box-shadow: none;
    color: ${props => props.color || props.theme.linkColor};
    font-size: 15px;
    line-height: 25px /* line height should be font-size + 10px */;
    text-transform: ${getTextTransform};

    ${props => props.shape !== 'circle' && 'height: fit-content'};
    ${props => props.shape !== 'circle' && `padding: ${getPadding(props)};`};
    text-align: ${props => props.align || 'center'};

    &:hover {
      outline: none;
    }

    ${props =>
      props.type === undefined &&
      css`
        margin-left: ${getMarginLeft};
      `}

    /* float messes up with layout, use position prop instead of push  */
    float: ${({ push }) => push || 'none'};

    ${({ position }) =>
      position === 'right' &&
      css`
        margin-left: auto;
        display: block;
      `}

    ${props =>
      props.type === 'bordered' &&
      css`
        background-color: transparent;
        border: ${props.size === 'large' ? '2px' : '1px'} solid
          ${props.border === 'primary' ? props.theme.primaryColor : 'white'};
        color: ${props.color === 'primary' ? props.theme.primaryColor : 'white'};
        font-weight: 600;
        &:hover {
          background-color: white;
          color: ${props.theme.primaryColor};
        }
        &[disabled] {
          cursor: not-allowed;
          background-color: transparent;
          border-color: white;
          &:hover {
            color: white;
          }
        }
      `}

    ${props =>
      props.type === 'white' &&
      css`
        background-color: #fff;
        border-color: #fff;
        color: ${({ color, theme }) => color || theme.primaryColor};

        &:hover {
          background-color: #fffe;
        }
      `}

    ${props =>
      props.type === 'cancel' &&
      css`
        margin: 0 30px;
        background-color: #b3b3b3;
        color: white;
        font-weight: ${props.weight === 'light' ? 0 : 600};
        &:hover {
          background-color: #666666;
        }
        &[disabled]:hover {
          background-color: #666666;
          color: white;
        }
      `}

    ${props =>
      props.type === 'primary' &&
      css`
        background-color: ${props.theme.primaryColor};
        color: white;
        font-weight: ${props.weight === 'light' ? 0 : 600};
        &:hover {
          background-color: ${props.theme.primaryColorHover};
        }
        &[disabled]:hover {
          background-color: ${props.theme.primaryColor};
          color: white;
        }
      `}

    ${props =>
      props.type === 'primaryghost' &&
      css`
        &&& {
          background-color: transparent;
          color: ${props.theme.primaryColor};
          border: ${() => `solid 1px ${props.theme.primaryColor}`};
          font-weight: ${props.weight === 'light' ? 0 : 600};
          &:hover {
            background-color: ${props.theme.primaryColorHover};
            color: white;
          }
          &[disabled]:hover {
            background-color: ${props.theme.primaryColor};
            color: white;
          }
        }
      `}

    ${props =>
      props.type === 'danger' &&
      css`
        background-color: ${props.theme.errorColor};
        color: white;
        font-weight: 600;
        &:hover {
          background-color: ${props.theme.errorColor};
        }
        &[disabled]:hover {
          background-color: ${props.theme.errorColor};
          color: white;
        }
      `}

    ${props =>
      props.type === 'parrobot' &&
      css`
        background-color: ${props.theme.parrotColor};
        color: white;
        font-weight: 600;
        &:hover {
          background-color: ${props.theme.parrotColor};
        }
        &[disabled]:hover {
          background-color: ${props.theme.parrotColor};
          color: white;
        }
      `}

    ${props =>
      props.disabled &&
      css`
        opacity: 0.3;
      `}

    &[ant-click-animating-without-extra-node]:after {
      border-color: ${({ theme }) => theme.primaryColor};
    }

    ${({ effect, theme }) =>
      effect === 'blink' &&
      css`
        animation: ${theme.type}-blink 1200ms infinite;
      `}
    ${({ effect }) =>
      effect === 'whiteBlink' &&
      css`
        animation: white-blink 1200ms infinite;
      `}

    ${[mainTheme, memberTheme, mentorTheme, parrotTheme].map(blinkKeyFrames)}

    &.ant-btn-background-ghost {
      color: #fff;
      background: transparent;
      border-color: #fff;
    }
  }
`;

export default Button;
