/**
 * A function that takes a language code and returns a Mentorship Agreement URL in a correspondent Language.
 *
 * @param {string} programLanguageCode - Code of program/mentorship language.
 * @returns {string} - A URL of the file.
 */

import { languages } from 'utils/translationHandlers';

export default languageCode => {
  switch (languageCode) {
    case languages.ES:
      // Spanish
      return 'https://s3.amazonaws.com/assets.bridgeforbillions.org/docs/ES+-+Mentorship+Agreement.pdf';
    case languages.FR:
      // French
      return 'https://s3.amazonaws.com/assets.bridgeforbillions.org/docs/FR+-+Mentorship+Agreement.pdf';
    case languages.PT:
      // Portuguese
      return 'https://s3.amazonaws.com/assets.bridgeforbillions.org/docs/PT+-+Mentorship+Agreement.pdf';
    case languages.BR:
      // Brasilian Portuguese
      return 'https://s3.amazonaws.com/assets.bridgeforbillions.org/docs/PT+-+Mentorship+Agreement.pdf';
    case languages.IT:
      // Italian
      return 'https://s3.amazonaws.com/assets.bridgeforbillions.org/docs/IT+-+Mentorship%2BAgreement.pdf';
    case languages.LV:
      // Latvian
      return 'https://s3.amazonaws.com/assets.bridgeforbillions.org/docs/Mentorship+Agreement_LV.pdf';
    case languages.SK:
      // Slovak
      return 'https://s3.amazonaws.com/assets.bridgeforbillions.org/docs/Mentorship+Agreement_SK.pdf';
    case languages.HY:
      // Armeninan
      return 'https://s3.amazonaws.com/assets.bridgeforbillions.org/docs/HY+-+Mentorship+Agreement.pdf';
    case languages.TH:
      // Thai
      return 'https://s3.amazonaws.com/assets.bridgeforbillions.org/docs/TH+-+Mentorship+Agreement.pdf';
    case languages.VI:
      // Vietnamese
      return 'https://s3.amazonaws.com/assets.bridgeforbillions.org/docs/VI+-+Mentorship+Agreement.pdf';
    case languages.LO:
      // Lao
      return 'https://s3.amazonaws.com/assets.bridgeforbillions.org/docs/Mentorship+Agreement+LO.pdf';
    case languages.KM:
      // Khmer
      return 'https://s3.amazonaws.com/assets.bridgeforbillions.org/docs/EN%2B-%2BMentorship%2BAgreement(km).pdf';
    default:
      // English
      return 'https://s3.amazonaws.com/assets.bridgeforbillions.org/docs/EN+-+Mentorship+Agreement.pdf';
  }
};
