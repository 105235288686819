import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'services/api';

// API module for listing the community profiles

export const communityProfilesApi = createApi({
  reducerPath: 'communityProfilesApi',
  baseQuery: axiosBaseQuery({
    apiPath: '/api/community-profiles/',
  }),
  endpoints: builder => ({
    // List ALL the profiles
    listCommunityProfiles: builder.query({
      query: () => '',
    }),
  }),
});

export const { useListCommunityProfilesQuery } = communityProfilesApi;
