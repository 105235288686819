// Libraries
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';

// Dependencies
import { SERVER_ERROR } from 'hocs/components/ErrorComponent/constants';
import MemberModel from 'models/Member';
import MemberApplication from 'models/MemberApplication';
import TranslationsContext from 'providers/translationsProvider';
import Loadable from 'utils/loadable';
import {
  MAINTENANCE_ANNOUNCEMENT,
  getMaintenanceNotificationTimeout,
  getMaintenanceRedirectTimeout,
  isMaintenanceModeEnabled,
} from 'utils/maintenance';
import queryString from 'utils/queryString';
import {
  APPLICATION,
  COMMUNITY,
  EXECUTIVE_SUMMARY,
  MENTOR_MATCHING,
  MY_PROGRAM_MATCHING,
  PROFILE,
} from './paths';

// Actions & Selectors
import { selectors as memberSelectors } from 'store/modules/member';
import { selectors as mentorSelectors } from 'store/modules/mentor';
import { actions as programActions } from 'store/modules/program';
import { selectors as projectSelectors } from 'store/modules/project';
import { selectors as userSelectors } from 'store/modules/user';

// Services
import teamService from 'services/team';

// Components
import { message, notification } from 'antd';
import ApiMessageHandler from 'components/ApiMessageHandler';
import WelcomePopup from 'components/WelcomePopup';
import PrivateRoute from 'containers/Routes/PrivateRoute';
import ErrorComponent from 'hocs/components/ErrorComponent';

const STATUS_INVITE_ACCEPTED = 1;

export const NEW_DASHBOARD_URL = '/';

const ProgramManagement = Loadable({
  importedComponent: () => import('views/PMAdmin'),
});

const SuperAdmin = Loadable({
  importedComponent: () => import('views/SuperAdmin'),
});

const Troubleshoot = Loadable({
  importedComponent: () => import('views/Troubleshoot'),
});

const Profile = Loadable({
  importedComponent: () => import('views/Profile'),
});

const MemberOutput = Loadable({
  importedComponent: () => import('views/MemberOutput'),
});

const MentorOutput = Loadable({
  importedComponent: () => import('views/MentorOutput'),
});

const Login = Loadable({
  importedComponent: () => import('views/Auth/Login'),
});

const Signup = Loadable({
  importedComponent: () => import('views/Auth/Signup'),
});

const Dashboard = Loadable({
  importedComponent: () => import('views/Dashboard'),
});

const MyProgram = Loadable({
  importedComponent: () => import('views/Matching/MemberMatching'),
});

const MentorMatching = Loadable({
  importedComponent: () => import('views/Matching/MentorMatching'),
});

const ResetPassword = Loadable({
  importedComponent: () => import('views/ResetPassword'),
});

const AreasOfInnovation = Loadable({
  importedComponent: () => import('views/AreasOfInnovation'),
});

const MyVenture = Loadable({
  importedComponent: () => import('views/MyVenture'),
});

const Community = Loadable({
  importedComponent: () => import('views/Community'),
});

const Tools = Loadable({
  importedComponent: () => import('views/Tools'),
});

const Settings = Loadable({
  importedComponent: () => import('views/Settings'),
});

const ExecutiveSummary = Loadable({
  importedComponent: () => import('views/ExecutiveSummary'),
});

const Application = Loadable({
  importedComponent: () => import('views/Application'),
});

// worker function to check if user can access tools
const checkToolAccess = (user, projects, t, pathname) => {
  const isAdmin = user.isAdmin();
  const isMentor = user.isMentor();

  if (pathname.includes('/tools') && !isMentor) {
    message.destroy(); // only display one message

    const projectId = pathname && pathname.match(/\d/g).join('');
    const venture = projects && projects.size > 0 ? projects.get(String(projectId)) : null;
    const ventureOwner = venture ? venture.owner : null;

    switch (true) {
      // COMMENT THIS CASE TO TEST THIS FEATURE
      case window.dev:
        return true;
      case isAdmin:
        return true;
      case ventureOwner && !isMentor && ventureOwner.status === MemberModel.STATUS_PAYMENT_FAILED:
        message.error(
          t(
            'Your next subscription payment is overdue. Please update your payment method in order to access the Tools again.'
          )
        );
        return false;
      case ventureOwner && !isMentor && ventureOwner.status !== MemberModel.STATUS_INCUBATING:
        message.error(
          t(
            'You don’t have access to this tool yet. If you believe you should, please contact us via the support chat.'
          )
        );
        return false;
      default:
        return true;
    }
  }
  return true;
};

const AppRoutes = props => {
  const { t } = useContext(TranslationsContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const { organization, program } = queryString.parse(document.location.search);
  const isCustomLink = Boolean(organization && program);
  const isMaintenanceMode = isMaintenanceModeEnabled();

  const [toolAccess, setToolAccess] = useState(true);
  const [wasRedirected, setWasRedirected] = useState(window.location.pathname !== '/');
  const [acceptingInvite, setAcceptingInvite] = useState(false);

  // Selectors
  const user = useSelector(userSelectors.getUser);
  const mentor = useSelector(mentorSelectors.getMentor);
  const member = useSelector(memberSelectors.getMember);
  const isAuthenticated = useSelector(userSelectors.isAuthenticated);
  const projects = useSelector(projectSelectors.getProjectsById);

  // Actions
  const { listPrograms } = programActions;
  const { listPublicPrograms } = programActions;

  const redirectOnLogin = () => {
    setWasRedirected(true);

    const ventureSummaryCompleted = member.application_venture_summary_complete;

    if (mentor.id && mentor.is_admin) {
      // If the user is an Admin
      navigate('/admin-dashboard');
    } else if (mentor.id || member.id) {
      // If the user is a Mentor or Entrepreneur
      switch (true) {
        // (Latest E Application) Status:
        //
        // Application Incomplete: application page 0
        case member.application_status === MemberApplication.STATUS_IN_PROGRESS:
          navigate(`/program/${member.application_program}/application`);
          break;
        // Application Pending: dashboard
        case member.application_status === MemberApplication.STATUS_PENDING_DECISION:
          navigate('/');
          break;
        // Unmatched: Mentor Matching
        case member.application_status === MemberApplication.STATUS_UNMATCHED:
          navigate(`/program/${member.application_program}/matching`);
          break;
        // Incubating: Tools dashboard
        case member.application_status === MemberApplication.STATUS_INCUBATING &&
          ventureSummaryCompleted:
          if (window.location.pathname.indexOf('tools') === -1) {
            // If user is not in workspace already
            navigate(`/venture/${member.application_project}/workspace`);
          }
          break;
        case member.application_status === MemberApplication.STATUS_INCUBATING &&
          !ventureSummaryCompleted:
          navigate(`/venture/${member.application_project}/venture-summary`);
          break;
        default:
          navigate(NEW_DASHBOARD_URL);
          break;
      }
    }
  };

  const initialize = () => {
    if (isAuthenticated && !wasRedirected) {
      dispatch(listPrograms());
      dispatch(listPublicPrograms());
      redirectOnLogin();
    }
  };

  // TODO: this should be moved down into the tools route
  useEffect(() => {
    if (user && projects) {
      setToolAccess(checkToolAccess(user, projects, t, location.pathname));
    }
  }, [props]);

  useEffect(() => {
    message.config({ duration: 10, maxCount: 1, top: 70 });
    if (member.id || (mentor.id && !mentor.isAdmin)) {
      initialize();
    }
    if (MAINTENANCE_ANNOUNCEMENT) {
      setTimeout(
        () =>
          notification.warning({
            description: t('Please, make sure to save all your work and come back later.'),
            duration: 0,
            message: t('Scheduled maintenance starting soon'),
          }),
        getMaintenanceNotificationTimeout()
      );
    }
    const maintenanceRedirectTimeout = getMaintenanceRedirectTimeout();
    if (maintenanceRedirectTimeout) {
      setTimeout(() => navigate('/maintenance'), maintenanceRedirectTimeout);
    }
  }, []);

  const acceptInvite = (memberToken, mentorToken) => {
    setAcceptingInvite(true);

    teamService.updateMemberInvite({
      id: memberToken || mentorToken,
      member: member && member.id,
      mentor: mentor && mentor.id,
      status: STATUS_INVITE_ACCEPTED,
    });
  };

  const { memberToken, mentorToken } = queryString.parse(document.location.search);
  if (isAuthenticated && !acceptingInvite && (memberToken || mentorToken) && (member || mentor)) {
    acceptInvite(memberToken, mentorToken);
  }
  if (member.id || mentor.id) {
    initialize();
  } else if (!isAuthenticated && wasRedirected) {
    setWasRedirected(false);
  }

  const isResetPassword = window.location.pathname.includes('reset-password');
  /**
   * Setting up the `rootAddress` dynamically depending on the user and its mentor status.
   */
  const rootAddress = isAuthenticated && Boolean(mentor.is_admin) ? '/admin-dashboard' : '/';

  if (isMaintenanceMode && !window.location.pathname.includes('maintenance')) {
    navigate('/maintenance');
  }

  const renderAddProgramPopup =
    isCustomLink &&
    isAuthenticated &&
    ((user.member && member.application_program !== program) ||
      (user.mentor && mentor.application_program !== program));

  if (renderAddProgramPopup) {
    sessionStorage.setItem('showJoinProgramModal', program);
  }

  return (
    <>
      {/*
        WelcomePopup is currently only for members. If we add more popups,
        add it WITHIN the component, not in Routes.js.
      */}
      {user.member && <WelcomePopup />}
      <ApiMessageHandler />

      {isMaintenanceMode ? (
        <Routes data-test="maintenance-Routes">
          <Route path="*" element={<ErrorComponent errorType={SERVER_ERROR} />} />
        </Routes>
      ) : (
        <Routes>
          <Route
            path="/login"
            element={
              isAuthenticated ? (
                <Navigate to={rootAddress} />
              ) : (
                <Login isCustomLink={isCustomLink} />
              )
            }
          />
          <Route
            path="/signup"
            element={
              isAuthenticated ? (
                <Navigate to={rootAddress} />
              ) : (
                <Signup isCustomLink={isCustomLink} />
              )
            }
          />
          <Route path="/reset-password" element={<ResetPassword />} />

          {/* PrivateRoute opening tag */}
          <Route path="/" element={<PrivateRoute />}>
            <Route
              path=""
              element={
                location.pathname !== rootAddress && !isResetPassword ? (
                  <Navigate to={rootAddress} />
                ) : (
                  <Dashboard />
                )
              }
            />
            <Route path="/admin-dashboard" element={<SuperAdmin />} />
            <Route path="/troubleshoot" element={<Troubleshoot />} />
            <Route path="/areas-of-innovation" element={<AreasOfInnovation />} />
            <Route path={EXECUTIVE_SUMMARY} element={<ExecutiveSummary />} />
            <Route path="/maintenance" element={<ErrorComponent errorType={SERVER_ERROR} />} />
            <Route path="/members/:memberId" element={<MemberOutput />} />
            <Route path={MENTOR_MATCHING} element={<MentorMatching />} />
            <Route path="/mentors/:mentorId" element={<MentorOutput />} />
            <Route path={PROFILE} element={<Profile />} />
            <Route
              path="/program-management/:organizationId/:programId/:kind/*"
              element={<ProgramManagement />}
            />
            <Route path={MY_PROGRAM_MATCHING} element={<MyProgram />} />
            <Route path={APPLICATION} element={<Application />} />
            <Route path="/programs-section/:slug" element={<Dashboard programDetail />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/venture/:id/*" element={<MyVenture />} />
            <Route path={`${COMMUNITY}/*`} element={<Community />} />
            <Route path="/tools/:projectId/:slug/*" element={toolAccess && <Tools />} />
          </Route>
          {/* PrivateRoute closing tag */}
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      )}
    </>
  );
};

export default AppRoutes;
