/* eslint-disable camelcase */
import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'services/api';

export const interestshipsApi = createApi({
  reducerPath: 'interestshipsApi',
  baseQuery: axiosBaseQuery({
    apiPath: '/api/interestships/',
  }),
  endpoints: builder => ({
    // List interestships, use program id as a parameter
    listInterestships: builder.query({
      query: ({ program, ...query }) => ({
        url: '',
        params: { program, ...query },
      }),
      providesTags: ['Interestships'],
    }),
    // Fetch a single interestship by id
    getInterestshipById: builder.query({
      query: id => ({
        url: `${id}/`,
      }),
    }),
    // Create a new interestship
    createInterestship: builder.mutation({
      query: ({ project, mentor, interest, availability, potential_match_level }) => ({
        url: '',
        method: 'POST',
        data: { project, mentor, interest, availability, potential_match_level },
      }),
    }),
    // Delete an interestship
    deleteInterestship: builder.mutation({
      query: id => ({
        url: `${id}/`,
        method: 'DELETE',
      }),
    }),
    // Update an interestship
    updateInterestship: builder.mutation({
      query: ({ id, ...updatedInterestship }) => ({
        url: `${id}/`,
        method: 'PATCH',
        data: updatedInterestship,
      }),
      invalidatesTags: (result, error, { skipInvalidation }) =>
        skipInvalidation ? [] : ['Interestships'], // Conditionally disable automated refetch
    }),
  }),
});

export const {
  useListInterestshipsQuery,
  useGetInterestshipByIdQuery,
  useCreateInterestshipMutation,
  useDeleteInterestshipMutation,
  useUpdateInterestshipMutation,
} = interestshipsApi;
