import { Record } from 'immutable';

// Assets
import { ReactComponent as iconMaybe } from 'static/icons/management-panel/potential-match/icon_maybe.svg';
import { ReactComponent as iconUndecided } from 'static/icons/management-panel/potential-match/icon_undecided.svg';
import { ReactComponent as iconCross } from 'static/icons/management-panel/potential-match/icon_x-in-circle.svg';
import { ReactComponent as iconStar } from 'static/icons/management-panel/potential-match/icon_star.svg';

const STATUS_PENDING = 0;
const STATUS_ACCEPTED_BY_E = 1;
const STATUS_REJECTED_BY_E = 2;
const STATUS_REJECTED_BY_M = 3;
const STATUS_AGREEMENT_READY = 4;
const STATUS_DELETED_DUE_TO_MATCHING_END = 5;
const STATUS_DISABLED_BY_MANAGER = 6;

export const getStatusDisplayForMentorTranslated = (status, t) =>
  ({
    [STATUS_PENDING]: t('pending'),
    [STATUS_ACCEPTED_BY_E]: t('accepted'),
    [STATUS_REJECTED_BY_E]: t('declined'),
    [STATUS_REJECTED_BY_M]: t('rejected'),
    [STATUS_AGREEMENT_READY]: t('agreement ready'),
  })[status];

export const getStatusDisplayForMentor = status =>
  ({
    [STATUS_PENDING]: 'pending',
    [STATUS_ACCEPTED_BY_E]: 'accepted',
    [STATUS_REJECTED_BY_E]: 'declined',
    [STATUS_REJECTED_BY_M]: 'rejected',
    [STATUS_AGREEMENT_READY]: 'agreement ready',
  })[status];

// Rejected Reason (Why the user is not interested in collaborating)
const REASON1 = 0;
const REASON2 = 1;
const REASON3 = 2;
const REASON4 = 3;
const REASON5 = 4;
const REASON6 = 5;
const REASON7 = 6;
export const REASON_OTHER = 7;
export const REASON_MENTOR_OTHER = 6;

// Reasons why an Entrepreneur rejects a Mentor
export const REJECTED_REASONS_ENTREPRENEUR = [
  REASON1,
  REASON2,
  REASON3,
  REASON4,
  REASON5,
  REASON6,
  REASON7,
  REASON_OTHER,
];

// Reasons why a Mentor rejects an Entrepreneur/Project
export const REJECTED_REASONS_MENTOR = [
  REASON1,
  REASON2,
  REASON3,
  REASON4,
  REASON5,
  REASON6,
  REASON_MENTOR_OTHER,
];

export const getReasonDisplay = (reason, t) =>
  ({
    [REASON1]: t("Not the skills I'm looking for"),
    [REASON2]: t('Not the right fit for my stage'),
    [REASON3]: t('Misaligned expectations'),
    [REASON4]: t("The mentor didn't really get my project"),
    [REASON5]: t('There was a language problem between us'),
    [REASON6]: t('There was a bad connection in the meeting'),
    [REASON7]: t("I don't think this mentor should be in the program"),
    [REASON_OTHER]: t('Other (Write your own)'),
  })[reason];

export const getMentorReasonDisplay = (reason, t) =>
  ({
    [REASON1]: t('Not the project I was expecting'),
    [REASON2]: t("I can't really help with what they need"),
    [REASON3]: t('I thought they were at a different stage of maturity'),
    [REASON4]: t('There was a language problem between us'),
    [REASON5]: t('There was a bad connection in the meeting'),
    [REASON6]: t("I don't think this entrepreneur/team should be in the program"),
    [REASON_MENTOR_OTHER]: t('Other (Write your own)'),
  })[reason];

// Meet Status
const MEET_NOT_YET = 0;
const MEET_YES = 1;
const MEET_NO = 2;
const MEET_NOT_INTERESTED = 3;

export const getInviteLabels = {
  [MEET_NOT_YET]: 'Not yet',
  [MEET_YES]: 'Yes',
  [MEET_NO]: 'No',
  [MEET_NOT_INTERESTED]: 'Not interested',
};

export const getMeetStatusDisplay = (status, t) => {
  switch (status) {
    case MEET_NOT_YET:
      return t("We didn't meet");
    case MEET_YES:
      return t('Yes');
    case MEET_NO:
      return t('No');
    case STATUS_AGREEMENT_READY:
      return t('Agreement Ready');
    case MEET_NOT_INTERESTED:
      return t('Not Interested');
    default:
      return null;
  }
};

const BEST_MATCH = 0;
const MAYBE = 1;
const UNDECIDED = 2;
const NOT_A_FIT = 3;

// Agreement
const AGREEMENT_PENDING = 0;
const AGREEMENT_SIGNED = 1;

export const getAdminMeetStatusDisplay = (interestship, t) => {
  const { status, e_agreement_status: eMeetStatus, m_agreement_status: mMeetStatus } = interestship;
  if (status === STATUS_AGREEMENT_READY) {
    if (eMeetStatus === AGREEMENT_SIGNED && eMeetStatus !== mMeetStatus) {
      return t('E signed');
    }
    if (mMeetStatus === AGREEMENT_SIGNED && mMeetStatus !== eMeetStatus) {
      return t('M signed');
    }
    if (eMeetStatus === AGREEMENT_SIGNED && eMeetStatus === mMeetStatus) {
      return t('Completed');
    }
    return t('Incomplete');
  }
  return '-';
};

export const potentialMatchOptions = [
  {
    value: 0,
    color: '#5185e0',
    text: 'Best Match',
    icon: iconStar,
    iconColor: '#00ffaf',
  },
  {
    value: 1,
    color: '#71A0E2',
    text: 'Maybe',
    icon: iconMaybe,
    iconColor: '#b5d7f4',
  },
  {
    value: 2,
    color: '#000000',
    text: 'Undecided',
    icon: iconUndecided,
    iconColor: '#000000',
  },
  {
    value: 3,
    color: '#f25a5a',
    text: 'Not a Fit',
    icon: iconCross,
    iconColor: '#f25a5a',
  },
];

const InterestshipRecord = Record({
  id: null,
  mentor: null,
  project: null,
  program: null,
  interest: null,
  skills: null,
  availability: null,
  e_accept_reply: null,
  status: null,
  e_meet_status: 0,
  m_meet_status: 0,
  e_agreement_status: AGREEMENT_PENDING,
  m_agreement_status: AGREEMENT_PENDING,
  e_ranking_submitted: null,
  m_ranking_submitted: null,
  e_likes: null,
  m_likes: null,
  e_rejected_reason: null,
  e_rejected_reason_other: null,
  m_rejected_reason: null,
  e_ranking: null,
  m_ranking: null,
  created: null,
  updated: null,
  meet_with_invite_status: {},
  potential_match_level: null,
  suggested_match_level: null,
  manual_invite: false,

  // constants
  STATUS_PENDING,
  STATUS_ACCEPTED_BY_E,
  STATUS_REJECTED_BY_E,
  STATUS_REJECTED_BY_M,
  STATUS_AGREEMENT_READY,
  MEET_NOT_YET,
  MEET_YES,
  MEET_NO,
  MEET_NOT_INTERESTED,
  AGREEMENT_PENDING,
  AGREEMENT_SIGNED,
  BEST_MATCH,
  MAYBE,
  UNDECIDED,
  NOT_A_FIT,
});

class Interestship extends InterestshipRecord {
  static STATUS_PENDING = STATUS_PENDING;
  static STATUS_ACCEPTED_BY_E = STATUS_ACCEPTED_BY_E;
  static STATUS_REJECTED_BY_E = STATUS_REJECTED_BY_E;
  static STATUS_REJECTED_BY_M = STATUS_REJECTED_BY_M;
  static STATUS_AGREEMENT_READY = STATUS_AGREEMENT_READY;
  static STATUS_DELETED_DUE_TO_MATCHING_END = STATUS_DELETED_DUE_TO_MATCHING_END;
  static STATUS_DISABLED_BY_MANAGER = STATUS_DISABLED_BY_MANAGER;

  static MEET_NOT_YET = MEET_NOT_YET;
  static MEET_YES = MEET_YES;
  static MEET_NO = MEET_NO;
  static MEET_NOT_INTERESTED = MEET_NOT_INTERESTED;

  static AGREEMENT_PENDING = AGREEMENT_PENDING;
  static AGREEMENT_SIGNED = AGREEMENT_SIGNED;

  static BEST_MATCH = BEST_MATCH;
  static MAYBE = MAYBE;
  static UNDECIDED = UNDECIDED;
  static NOT_A_FIT = NOT_A_FIT;
}

export default Interestship;
