// Libraries
import { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Components
import InfoBubble from 'components/elements/InfoBubble';
import { Form as AntForm } from 'antd';

// Dependencies
import bfbTheme from 'styles/b4b-theme';
import TranslationsContext from 'providers/translationsProvider';

const Item = props => {
  const {
    align,
    infotext,
    placement,
    trigger,
    label,
    noAsterisk,
    displayBlock,
    hiddenLabel,
    required,
    rules,
    name,
    ...rest
  } = props;

  const { t } = useContext(TranslationsContext);

  const requiredRule = required && [{ required: true, message: t('This field is required') }];
  const baseRules = rules.length > 0 && rules;
  const isRequired = required || rules.some(rule => rule.required);

  return (
    <StyledFormItem
      $displayBlock={displayBlock}
      name={name}
      rules={baseRules || requiredRule || []}
      label={
        (label || infotext) && (
          <div className={`label-content ${hiddenLabel ? 'label-content--hidden' : ''}`}>
            {label && (
              <>
                {label}
                {isRequired && !noAsterisk && <span className="asterisk">*</span>}
              </>
            )}
            {infotext && (
              <InfoBubble
                key="info-help"
                align={align}
                infotext={infotext}
                placement={placement}
                trigger={trigger}
                style={{ marginLeft: '10px' }}
              />
            )}
          </div>
        )
      }
      data-test-id="custom-form-item"
      {...rest}
    />
  );
};

// TODO: Prop "size" is misleading and can conflict with Ant native size prop for Inputs.
// It should be renamed to "mb" or "marginBottom".

const marginBottomOptions = {
  large: 35,
  intermediate: 25, // Used in form items that are meant to have "large" margin-bottom but already have a margin coming from the input
  default: 12,
  medium: 10,
  small: 6,
  none: 0,
};

const StyledFormItem = styled(AntForm.Item)`
  &&& {
    margin-bottom: ${({ size }) =>
      `${size ? marginBottomOptions[size] : marginBottomOptions.default}px`};

    // Defines whether the label and the inputs/textarea are displayed inline (default) or in block
    .ant-row.ant-form-item-row {
      display: ${props => props.$displayBlock && 'block'};
    }

    .ant-form-item-label {
      text-align: left;
      white-space: unset;

      label {
        font-weight: 600;
        display: inline;

        &::before,
        &::after {
          content: none;
        }

        .asterisk {
          color: ${bfbTheme.error};
          display: inline-block;
          font-family: SimSun;
          font-size: 15px;
          line-height: 1;
          margin-left: 4px;
          margin-right: 0;
        }

        .label-content {
          margin-bottom: 10px;
        }

        /* Label becomes invisible while respecting the spacings within the input, preventing the input from folding up */
        .label-content--hidden {
          visibility: hidden;
        }
      }
    }

    .ant-form-explain {
      margin-top: -1px;
      font-size: 14px;
      line-height: 24px;
    }

    .ant-form-item-extra {
      margin-bottom: 10px;
      font-weight: 600;
    }
  }

  /* Place extra text between the lable and the first input (default placement: under the input) */
  &:has(.ant-form-item-extra) .ant-form-item-control {
    display: flex;

    /* Extra text */
    .ant-form-item-extra {
      order: 1;
    }

    /* Main input */
    .ant-form-item-control-input {
      order: 2;
    }

    /* Error message */
    div:has(.ant-form-item-explain-error) {
      order: 3;
    }

    &.has-error .ant-calendar-picker-input {
      border-bottom-color: red;
    }
  }
`;

Item.propTypes = {
  align: PropTypes.instanceOf(Object),
  infotext: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  placement: PropTypes.string,
  trigger: PropTypes.string,
  noAsterisk: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  displayBlock: PropTypes.bool,
  hiddenLabel: PropTypes.bool,
  required: PropTypes.bool,
  rules: PropTypes.instanceOf(Array),
  name: PropTypes.string,
};

Item.defaultProps = {
  align: undefined,
  infotext: undefined,
  placement: undefined,
  trigger: undefined,
  noAsterisk: false,
  label: undefined,
  displayBlock: false,
  hiddenLabel: false,
  required: false,
  rules: [],
  name: '',
};

export default Item;
