const EXECUTIVE_SUMMARY = '/executive-summary/:projectId/:step';
const APPLICATION = '/program/:id/application';
const PROFILE = '/profile';
const MY_PROGRAM_MATCHING = '/program/:id/matching';
const MY_VENTURE = '/venture/:id/workspace';
const SETTINGS = '/settings';
const MANAGEMENT_PANEL_PROJECT = '/program-management/:organizationId/:programId/projects';
const MENTOR_MATCHING = '/mentor-matching/:id/*';
const COMMUNITY = '/community';
const COMMUNITY_SETTINGS = '/community/:profileId/settings';

export {
  APPLICATION,
  EXECUTIVE_SUMMARY,
  MANAGEMENT_PANEL_PROJECT,
  MY_PROGRAM_MATCHING,
  MY_VENTURE,
  PROFILE,
  SETTINGS,
  COMMUNITY,
  COMMUNITY_SETTINGS,
  MENTOR_MATCHING,
};
