import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Checkbox as AntCheckbox } from 'antd';

const Checkbox = ({ onChange = null, ...rest }) => {
  const handleOnChange = event => {
    const data = { ...event };
    data.target.value = data.target.checked;
    if (onChange) onChange(data);
  };

  return <StyledCheckbox onChange={handleOnChange} {...rest} />;
};

const StyledCheckbox = styled(AntCheckbox)`
  &&& {
    .ant-checkbox-inner {
      width: ${({ size }) => (size === 'large' ? '25px' : '17.5px')};
      height: ${({ size }) => (size === 'large' ? '25px' : '17.5px')};
      border-radius: 4px;
    }

    .ant-checkbox {
      align-self: start;
      margin-top: ${({ size }) => (size === 'large' ? '0' : '5px')};

      &.ant-checkbox-indeterminate {
        .ant-checkbox-inner::after {
          background-color: ${({ theme, backgroundColor }) =>
            backgroundColor || theme.secondaryColor};
          height: 12px;
          width: 12px;
        }
      }

      &.ant-checkbox-checked {
        &::after {
          visibility: hidden;
        }

        .ant-checkbox-inner {
          background-color: ${({ theme, backgroundColor }) =>
            backgroundColor || theme.secondaryColor};
          border: none;

          &:after {
            height: 66%;
            left: 21%;
            top: 45%;
            width: 33%;
          }
        }
      }
    }
  }
`;

Checkbox.propTypes = {
  onChange: PropTypes.func,
};

Checkbox.Group = StyledCheckbox.Group;

export default Checkbox;
