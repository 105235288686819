// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Components
import { Progress, Col, Popover } from 'antd';
import { Row } from 'components/layout';

const ApprovedProgressStats = ({ data, t, module = '' }) => {
  const colorApproved = {
    regular: '#1A754E',
    fade: '#1A754E1A',
    reject: '#999999',
  };

  const totalApplicationNumber =
    module === 'projects' ? data.total_e_applications : data.total_m_applications;

  const totalAppApproved = data.approved.approved;
  const totalApprovedRate = Math.round((totalAppApproved / totalApplicationNumber) * 100);
  const acceptanceRate = Math.round((totalAppApproved / totalApplicationNumber) * 100);
  const rejectionRate = Math.round((data.approved.rejected / totalApplicationNumber) * 100);

  return (
    <Wrapper color={colorApproved}>
      <Row type="flex" justify="space-between" gutter={20}>
        <Col>
          <div className="text">
            {t('App. Approved')}
            <span data-test-id="app-approved">{totalAppApproved}</span>
          </div>
          <div className="text">
            {t('App. Rejected')}
            <span data-test-id="app-rejected" style={{ color: colorApproved.reject }}>{data.approved.rejected}</span>
          </div>
        </Col>
        <Col>
          <div className="text">
            {t('Acceptance Rate')}
            <span data-test-id="acceptance-rate">{acceptanceRate} %</span>
          </div>
          <div className="text">
            {t('Rejection Rate')}
            <span data-test-id="rejection-rate" style={{ color: colorApproved.reject }}>{rejectionRate} %</span>
          </div>
        </Col>
      </Row>
      <Popover
        placement="bottom"
        content={
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-around',
              minWidth: '150px',
            }}
          >
            {t('Total Application Submitted')}
            <span style={{ color: '#1A754E', marginLeft: '10px' }}>{data.approved.approved}</span>
          </div>
        }
        trigger="hover"
      >
        <Progress
          strokeColor={rejectionRate > 0 ? colorApproved.reject : colorApproved.regular}
          percent={totalApprovedRate || 0}
          success={{ percent: rejectionRate || 0 }}
          showInfo={false}
        />
      </Popover>
    </Wrapper>
  );
};

ApprovedProgressStats.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  t: PropTypes.func.isRequired,
  module: PropTypes.string,
};

const Wrapper = styled.div`
  &&& {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px;
    max-width: ${({ singleCol }) => (singleCol ? '180px' : '380px')};
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 2px 8px 0 ${({ color }) => color.fade};
    color: ${({ color }) => color.regular};
    .text {
      color: #000000;
      min-width: 120px;
      display: flex;
      flex-direction: row;
      white-space: nowrap;
      justify-content: space-between;
      margin-bottom: 5px;
      span {
        color: ${({ color }) => color.regular};
        margin-left: 20px;
      }
    }
    .ant-progress-inner {
      background-color: ${({ color }) => color.fade};
    }

    .ant-progress-success-bg {
      background-color: ${({ color }) => color.regular};
    }
  }
`;

export default ApprovedProgressStats;
